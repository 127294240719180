export async function postFile(fullUrl: string, file: File, onProgress: (percentage: number) => void): Promise<any> {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", file);

        const xhr = new XMLHttpRequest();

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                onProgress((event.loaded / event.total) * 100);  // In percentage
            }
        };

        xhr.open("POST", fullUrl);

        xhr.onload = () => {
            if (xhr.status === 201) {
                resolve(xhr.response);
            } else {
                reject(new Error(`Upload failed with status ${xhr.status}`));
            }
        };

        xhr.onerror = () => {
            reject(new Error('Upload failed due to a network error.'));
        };

        xhr.send(formData);
    });
}
