import React from "react";
import {Skeleton} from "baseui/skeleton";
import {PLACEMENT, StatefulPopover} from "baseui/popover";
import {StatefulMenu} from "baseui/menu";
import {Button, KIND} from "baseui/button";
import {FiChevronDown} from "react-icons/fi";
import {useChangeCurrentPlatformAction, useCurrentPlatform, usePlatforms} from "../../services/auth/actions";
import {Platform} from "../../services/auth/types";


const PlatformSelectorButton: React.FC<{ platform: Platform, showMore: boolean }> = ({platform, showMore}) => {
    return (
        <Button
            kind={KIND.tertiary}
            style={{marginLeft: "-15px"}}
        >
            <b>{platform.name}</b>{showMore && <FiChevronDown size={18} style={{marginLeft: 2}}/>}
        </Button>
    )
}


export const PlatformSelector: React.FC = () => {
    const {data: platforms, isLoading} = usePlatforms();
    const currentPlatform = useCurrentPlatform()
    const changePlatformAction = useChangeCurrentPlatformAction();

    const hasManyPlatforms = !!platforms && platforms.length > 1;

    if (isLoading || !platforms || !currentPlatform) {
        return <Skeleton animation={true} width={"100px"} height={"25px"}/>
    }

    const menuItems = platforms.map(platform => ({label: platform.name, value: platform.id}))

    return (
        <React.Fragment>
            {hasManyPlatforms ? (
                <StatefulPopover
                    focusLock={true}
                    placement={PLACEMENT.bottom}
                    accessibilityType={'menu'}
                    content={() => (
                        <div style={{minWidth: 150}}>
                            <StatefulMenu
                                items={menuItems}
                                onItemSelect={({item}) => changePlatformAction.change(item.value)}
                            />
                        </div>
                    )}
                >
                    <div>
                        <PlatformSelectorButton platform={currentPlatform} showMore={true}/>
                    </div>
                </StatefulPopover>
            ) : (
                <div>
                    <PlatformSelectorButton platform={currentPlatform} showMore={false}/>
                </div>
            )}
        </React.Fragment>
    )
}
