import {Heading, HeadingLevel} from "baseui/heading";

import React from "react";
import {Button} from "baseui/button";
import {Delete, Plus} from "baseui/icon";
import {Link} from "../../../components/Link/Link";
import {CenteredDiv} from "../../../components/CenteredDiv/CenteredDiv";
import {ListItem, ListItemLabel} from "baseui/list";
import {Badge, COLOR, HIERARCHY, SHAPE} from "baseui/badge";
import {useStyletron} from "baseui";


export const EventBadge: React.FC<{ e: string }> = ({e}) => {
    return (
        <Badge
            content={e}
            hierarchy={HIERARCHY.secondary}
            shape={SHAPE.pill}
            color={COLOR.primary}
            overrides={{Badge: {style: {marginRight: '8px'}}}}
        />
    );
};

export function WebhookPage() {
    const [css] = useStyletron();

    const webhooks = [
        {
            "url": "https://platform.com/api/v1/webhooks/legacy/",
            "events": [
                "video.created",
                "video.updated",
                "video.deleted",
            ],
            "status": "Active",
        },
        {
            "url": "https://platform.com/api/v2/webhooks/video/",
            "events": [
                "video.created",
                "video.updated",
                "event_a",
                "event_b",
                "event_c",
            ],
            "status": "Active",
        },
    ]

    const eventBadgeDisplayCount = 2

    return (
        <CenteredDiv>
            <div style={{"width": "80%", "marginTop": "50px"}}>
                <HeadingLevel>
                    <div
                        style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}
                    >
                        <Heading>
                            Webhooks
                        </Heading>
                        <Link to={"/settings/webhooks/new"}>
                            <Button
                                startEnhancer={() => <Plus size={24}/>}
                            >
                                Webhook endpoint
                            </Button>
                        </Link>
                    </div>

                </HeadingLevel>

                <div>
                    <ul
                        className={css({
                            width: '100%',
                            paddingLeft: 0,
                            paddingRight: 0,
                        })}
                    >
                        {webhooks.map(webhook => (
                            <ListItem
                                endEnhancer={() => (
                                    <React.Fragment>
                                        <Badge
                                            content={webhook.status}
                                            shape={SHAPE.pill}
                                            color={COLOR.positive}

                                        />
                                        <div style={{width: '18px'}} />
                                        <Button
                                            shape="round"
                                            size="compact"
                                            kind="primary"
                                            colors={{backgroundColor: "rgb(99 99 99)", color: "white"}}
                                            disabled={false}
                                        >
                                            <Delete />
                                        </Button>
                                    </React.Fragment>
                                )}
                            >
                                <ListItemLabel>{webhook.url}</ListItemLabel>

                                <ListItemLabel>
                                    {webhook.events.slice(0, eventBadgeDisplayCount).map(e => (
                                        <EventBadge e={e}/>
                                    ))}
                                    {
                                        webhook.events.length > eventBadgeDisplayCount && (
                                            <EventBadge e={`+${webhook.events.length-eventBadgeDisplayCount} more`}/>
                                        )
                                    }

                                </ListItemLabel>

                            </ListItem>
                        ))}
                    </ul>
                </div>

            </div>

        </CenteredDiv>
    )
}