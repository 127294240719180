import * as React from 'react';
import {FileUploader} from 'baseui/file-uploader';
import {Locale} from 'baseui/locale/types';
import en_US from 'baseui/locale/en_US';
import {LocaleProvider} from "baseui";

interface FileUploadProps {
    progressAmount: number;
    onFileSelected: (file: File | null) => void;
    disabled: boolean;
    maxSize: number;
}


export function FileUpload(
    {
        progressAmount,
        onFileSelected,
        disabled,
        maxSize,
    }: FileUploadProps
) {
    const [file, setFile] = React.useState<File | null>(null);

    const locale: Locale = {
        ...en_US,
        fileuploader: {
            ...en_US.fileuploader,
            dropFilesToUpload: !file ? 'Drop files here to upload' : `Selected file: ${file.name}`,
            browseFiles: !file ? 'Browse files' : 'Change file',
        },
    };

    return (
        <LocaleProvider
            locale={locale}
        >
            <FileUploader
                onCancel={() => onFileSelected(null)}
                onDrop={(acceptedFiles, rejectedFiles) => {
                    if (!!rejectedFiles.length) {
                        //todo message to switch to tus
                        console.log("rejected")
                        console.log(rejectedFiles)
                    }

                    setFile(acceptedFiles[0]);
                    onFileSelected(acceptedFiles[0]);
                }}
                progressAmount={progressAmount}
                progressMessage={
                    progressAmount
                        ? `Uploading ${progressAmount.toFixed(2)}%...`
                        : ''
                }
                multiple={false}
                disabled={disabled}
                maxSize={maxSize}
            />
        </LocaleProvider>

    );
}
