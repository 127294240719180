import React, { useState } from 'react';
import { Block } from 'baseui/block';
import { Input } from 'baseui/input';
import { Button, KIND, SIZE } from 'baseui/button';
import copy from 'copy-to-clipboard';
import { FormControl } from "baseui/form-control";

export const CodeFragment: React.FC<{
    label: string,
    code: string,
    isMasked?: boolean,
    maskStart?: number,
    maskEnd?: number
}> = ({ label, code, isMasked = false, maskStart = 0, maskEnd = code.length }) => {
    const [buttonText, setButtonText] = useState("Copy to clipboard");

    const copyToClipboard = () => {
        copy(code);
        setButtonText("Copied!");
        setTimeout(() => setButtonText("Copy to clipboard"), 1400);
    };

    const displayCode = isMasked
        ? code.substring(0, maskStart) + "*".repeat(maskEnd - maskStart) + code.substring(maskEnd)
        : code;

    return (
        <Block>
            <FormControl
                label={label}
                overrides={{
                    ControlContainer: {
                        style: {
                            marginBottom: "4px",
                        },
                    },
                }}
            >
                <Input
                    value={displayCode}
                    readOnly
                    overrides={{
                        Input: {
                            style: {
                                fontFamily: 'Lucida Console, Monaco, monospace',
                            },
                        },
                    }}
                />
            </FormControl>
            <Button
                onClick={copyToClipboard}
                size={SIZE.mini}
                kind={KIND.tertiary}
            >
                {buttonText}
            </Button>
        </Block>
    );
};
