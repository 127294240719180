import {
    matchPath,
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import {Navbar} from "../../components/Navbar/Navbar";
import {Navigation} from "baseui/side-navigation";
import {VideosPage} from "../VideosPage/VideosPage";
import {VideoPage} from "../VideoPage/VideoPage";
import {UploadPage} from "../UploadPage/UploadPage";
import {WebhookPage} from "../settings/WebhookPage/WebhookPage";
import React from "react";
import {TeamPage} from "../settings/TeamPage/TeamPage";
import {ConfigurationPage} from "../settings/ConfigurationPage/ConfigurationPage";


const sidenav = [
    {
        title: 'Videos',
        itemId: '/videos',
    },
    {
        title: 'Analytics',
        // itemId: '/analytics',
        subNav: [
            {
                title: 'Usage',
                itemId: '/analytics/usage'
            },
            {
                title: 'Views',
                itemId: '/analytics/views'
            },
        ]
    },
    {
        title: 'Settings',
        // itemId: '/settings/',
        subNav: [
            {
                title: 'Configuration',
                itemId: '/settings/config'
            },
            {
                title: 'Webhooks',
                itemId: '/settings/webhooks'
            },
            {
                title: 'Team',
                itemId: '/settings/team'
            },
        ]
    },
    {
        title: 'Developers',
        // itemId: '/developers',
        subNav: [
            {
                title: 'Logs',
                itemId: '/developers/logs'
            },
            {
                title: 'Events',
                itemId: '/developers/events'
            },
        ]
    },
];


const extractItemIds = (items: any) => {
    let itemIds: any = [];

    const extractIds = (items: any) => {
        items.forEach((item: any) => {
            if (item.itemId) {
                itemIds.push(item.itemId);
            }
            // Check if the item has a subNav and process it recursively
            if (item.subNav && item.subNav.length > 0) {
                extractIds(item.subNav);
            }
        });
    };

    extractIds(items);
    return itemIds;
};

const itemIds = extractItemIds(sidenav);

const routeToItemIdMap = new Map<string, string>([
    ["videos/:hash", "/videos"],
    ["upload/", "/videos"],
])


export function Dashboard() {
    let location = useLocation();
    let navigate = useNavigate();

    let activeItemId = "none"
    if (itemIds.includes(location.pathname)) {
        activeItemId = location.pathname;
    } else {
        routeToItemIdMap.forEach((value, key) => {
            let match = matchPath(key, location.pathname);
            if (match !== null){
                activeItemId = value;
            }
        })
    }

    const navbarColor = "rgb(253 253 253)";
    const sidebarColor = "rgb(253 253 253)";

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <Navbar color={navbarColor} />
            <div style={{ display: "flex", flex: 1, overflow: "hidden" }}>
                <div style={{
                    backgroundColor: sidebarColor,
                    padding: "20px 0px 0px 10px",
                    overflowY: "auto",
                    minWidth: "275px",
                    borderRight: "solid 1px rgb(226, 226, 226)"
                }}>
                    <Navigation
                        items={sidenav}
                        activeItemId={activeItemId}
                        onChange={({event, item}) => {
                            // prevent page reload
                            event.preventDefault();
                            navigate(item.itemId || '/');
                        }}
                    />
                </div>
                <div style={{ flex: 1, overflowY: "auto", width: "100%"}}>
                    <Routes >
                        <Route path="/videos" element={<VideosPage />} />
                        <Route path="/videos/:hash" element={<VideoPage />} />
                        <Route path="/upload" element={<UploadPage />} />
                        <Route path="/settings/config" element={<ConfigurationPage />} />
                        <Route path="/settings/webhooks" element={<WebhookPage />} />
                        <Route path="/settings/team" element={<TeamPage />} />

                        <Route
                            path="/"
                            element={<Navigate to="/videos" replace={true} />}
                        />
                        <Route
                            path="/login"
                            element={<Navigate to="/videos" replace={true} />}
                        />
                    </Routes>
                </div>
            </div>
        </div>

    )
}
