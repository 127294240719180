import {HeadingLevel} from "baseui/heading";
import {HeadingXSmall, LabelLarge, MonoHeadingMedium, ParagraphSmall} from "baseui/typography";
import {PlaylistStatus, VideoStatus} from "../../services/video/enums";
import {VideoStatusTag} from "../../components/VideoStatusTag/VideoStatusTag";
import React, {ReactNode, useEffect} from "react";
import {SIZE as DIVIDERSIZE, StyledDivider} from 'baseui/divider';
import {Button, KIND, SIZE} from "baseui/button";
import {ArrowLeft} from "baseui/icon";
import {Link} from "../../components/Link/Link";
import {CodeFragment} from "../../components/CodeFragment/CodeFragment";
import {formatDistanceToNow} from "date-fns";
import {
    PlaylistProcessingProgressBar
} from "../../components/PlaylistProcessingProgressBar/PlaylistProcessingProgressBar";
import {useParams} from "react-router-dom";
import {Spinner} from "baseui/spinner";
import {useUpdateVideo, useVideo} from "../../services/video/actions";
import {qualityToNumber} from "../../services/video/utils";
import {VideoPlayer} from "../../components/VideoPlayer/VideoPlayer";
import {FormControl} from "baseui/form-control";
import {Checkbox} from "baseui/checkbox";
import {getMasterPlaylistUrl} from "../../services/video/types";
import {CenteredDiv} from "../../components/CenteredDiv/CenteredDiv";


type SectionProps = {
    children: ReactNode;
    name: string;
};


const Section = ({children, name}: SectionProps) => {
    return (
        <div style={{marginTop: 40}}>
            <HeadingXSmall style={{margin: 0}}>{name}</HeadingXSmall>
            <StyledDivider $size={DIVIDERSIZE.section}/>
            {children}
        </div>
    )
}


export function VideoPage() {
    const {hash} = useParams();
    const videoHash = hash || "";

    const [signedUrlsRequired, setSignedUrlsRequired] = React.useState<boolean|null>(null);
    const [settingsFormIsDisabled, setSettingsFormIsDisabled] = React.useState<boolean>(false);

    const {data: video, isLoading, isError, error, refetch} = useVideo(videoHash, 1000);

    useEffect(() => {
        if (video && signedUrlsRequired == null){
            setSignedUrlsRequired(video.require_signed_urls)
        }
    }, [signedUrlsRequired, video])

    const updateVideoAction = useUpdateVideo();

    const onSignedUrlsCheckboxClick = () => {
        if (video && signedUrlsRequired != null){
            setSettingsFormIsDisabled(true);
            const newValue = !signedUrlsRequired;
            updateVideoAction.use(video, newValue).then(
                () => {
                    setSignedUrlsRequired(newValue);
                    setSettingsFormIsDisabled(false);
                }
            )
        }
    }

    const manifestUrl = video ? getMasterPlaylistUrl(video) : null;

    return (
        <CenteredDiv>
            <div style={{"width": "80%", "marginTop": "50px"}}>
                <div style={{marginBottom: 12}}>
                    <Link to={"/videos"}>
                        <Button
                            kind={KIND.tertiary}
                            size={SIZE.compact}
                            startEnhancer={() => <ArrowLeft size={24}/>}
                        >
                            All videos
                        </Button>
                    </Link>
                </div>
                {(!video) ? (
                    <div style={{display: "flex", justifyContent: "center", paddingTop: 300}}>
                        <Spinner $size="100px" $borderWidth="6px"/>
                    </div>
                ) : (<div>
                    <HeadingLevel>
                        <div style={{
                            "display": "flex",
                            "alignItems": "center",
                            "justifyContent": "space-between"
                        }}>
                            <MonoHeadingMedium style={{margin: 0}}>{video.hash}</MonoHeadingMedium>
                            <VideoStatusTag status={video.status}/>
                        </div>


                        <ParagraphSmall style={{margin: 0}}>
                            {`Created ${formatDistanceToNow(video.created)} ago`}
                        </ParagraphSmall>
                    </HeadingLevel>
                    <StyledDivider $size={DIVIDERSIZE.section}/>

                    {!!manifestUrl && (
                    <Section name="Video">
                        <VideoPlayer
                            master_playlist_url={manifestUrl}
                            thumbnail_url={video.thumbnail_url || "https://user-images.githubusercontent.com/101482/29592647-40da86ca-875a-11e7-8bc3-941700b0a323.png"}
                        />
                    </Section>
                    )}

                    <Section name="Settings">
                        <div>
                            <div style={{paddingTop: 6}}></div>

                            <FormControl
                                label="Video protection"
                                caption="Signed URLs allow for strict access control"
                                disabled={settingsFormIsDisabled}
                            >
                                <Checkbox
                                    checked={signedUrlsRequired == null ? false : signedUrlsRequired}
                                    onChange={onSignedUrlsCheckboxClick}
                                >
                                    Signed URLs
                                </Checkbox>
                            </FormControl>

                            {
                                signedUrlsRequired &&
                                <div>
                                    <CodeFragment label="URL signing secret" code={video.signed_urls_secret_key}/>
                                </div>
                            }

                        </div>

                    </Section>

                    <Section name="Metadata">

                        <div>
                            <div style={{paddingTop: 6}}></div>
                            <CodeFragment label="Video hash" code={video.hash}/>
                        </div>

                        {
                            video.master_hls_manifest_url &&
                            <div>
                                <div style={{paddingTop: 12}}></div>
                                <CodeFragment label="HLS master playlist" code={video.master_hls_manifest_url}/>
                            </div>
                        }

                        {video.playlists
                            .filter(playlist => playlist.status === PlaylistStatus.READY && playlist.url != null)
                            .sort((a, b) => qualityToNumber(b.quality) - qualityToNumber(a.quality))
                            .map((playlist, index) => (
                                <div key={index}>
                                    <div style={{paddingTop: 12}}></div>
                                    <CodeFragment label={`HLS ${playlist.quality} playlist`} code={playlist.url}/>
                                </div>
                            ))}

                    </Section>

                    {(video.status !== VideoStatus.READY && !!video.playlists.length) && <Section name="Processing">
                        <div style={{paddingTop: 4, paddingBottom: 4}}></div>

                        {video.playlists.sort((a, b) => qualityToNumber(b.quality) - qualityToNumber(a.quality)).map((playlist, index) => (
                            <React.Fragment key={index}>
                                {index !== 0 && (
                                    <div style={{paddingTop: 4, paddingBottom: 4}}>
                                        <StyledDivider $size={DIVIDERSIZE.cell}/>
                                    </div>
                                )}
                                <LabelLarge>{playlist.quality} quality</LabelLarge>
                                <PlaylistProcessingProgressBar playlist={playlist}/>
                            </React.Fragment>
                        ))}

                        <div style={{paddingTop: 80}}></div>
                    </Section>}

                </div>)}

            </div>

        </CenteredDiv>
    )
}