import {Item, StatefulMenu} from "baseui/menu";
import {logout} from "../../services/auth/actions";
import {PLACEMENT, StatefulPopover, TRIGGER_TYPE} from "baseui/popover";
import React from "react";
import {Avatar} from "baseui/avatar";


const AvatarWithRef = React.forwardRef((props: any, ref) => {
    return (
        <Avatar
            overrides={{
                Root: {
                    props: {ref: ref, ...props},
                },
            }}
            {...{...props, innerRef: ref}}
        />
    );
});

export function UserAvatar() {
    const ITEMS = [
        {label: 'Settings', value: 'settings'},
        {label: 'Logout', value: 'logout'},
    ];

    const onItemSelect = (item: Item, close: () => void) => {
        switch (item.value) {
            case "logout": {
                logout();
                break;
            }
        }
        close();
    }

    return (
        <StatefulPopover
            focusLock={true}
            placement={PLACEMENT.bottom}
            accessibilityType={'tooltip'}
            triggerType={TRIGGER_TYPE.click}
            content={({close}) => (
                <StatefulMenu
                    items={ITEMS}
                    onItemSelect={({item}) => onItemSelect(item, close)}
                />
            )}
        >
            <AvatarWithRef
                name="Jane Doe"
                src="https://avatars.dicebear.com/api/human/yard.svg?width=285&mood=happy"
                style={{"cursor": "pointer"}}
            />
        </StatefulPopover>
    );
}
