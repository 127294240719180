import React from 'react';
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {BaseProvider, LightTheme} from 'baseui';
import {LoginPage} from "../LoginPage/LoginPage";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import {useIsLoggedIn} from "../../services/auth/hooks";
import {Dashboard} from "../Dashboard/Dashboard";
import {UploadsCache} from "../../services/video/cache";

const engine = new Styletron();
const queryClient = new QueryClient()
export const uploadsCache = new UploadsCache();


function App() {
    const isLoggedIn = useIsLoggedIn();


    return (
        <BrowserRouter>
            <StyletronProvider value={engine}>
                <BaseProvider theme={LightTheme}>
                    <QueryClientProvider client={queryClient}>
                        {isLoggedIn ? <Dashboard/> : <NotLoggedIn/>}
                    </QueryClientProvider>
                </BaseProvider>
            </StyletronProvider>
        </BrowserRouter>
    );
}


function NotLoggedIn() {
    return (
        <div>
            <Routes>
                <Route path="/login" element={<LoginPage/>}/>
                <Route
                    path="*"
                    element={<Navigate to="/login" replace={true} />}
                />
            </Routes>
        </div>
    )
}


export default App;
