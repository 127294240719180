
export interface ValidatorResult {
    isValid: boolean,
    errorMessage: string|null
}

export interface Validator {
    validate: (value: any) => ValidatorResult
}


export const EmailValidator : Validator = {
    validate(value: string): ValidatorResult {
        if(value.length === 0){
            return {isValid: false, errorMessage: "Email is required"}
        }

        if(!value.includes("@") || value.includes(" ")){
            return {isValid: false, errorMessage: "Invalid email"}
        }

        return {isValid: true, errorMessage: null};
    }
}

export const PasswordValidator : Validator = {
    validate(value: string): ValidatorResult {
        if(value.length === 0){
            return {isValid: false, errorMessage: "Password is required"}
        }

        if(value.length < 6){
            return {isValid: false, errorMessage: "Password is too short"}
        }

        return {isValid: true, errorMessage: null};
    }
}

