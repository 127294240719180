import React from "react";
import {
    Card,
    StyledContents
} from "baseui/card";
import {
    MonoParagraphMedium,
    ParagraphSmall,
} from "baseui/typography";
import {formatDistanceToNow} from 'date-fns';
import {Link} from "../Link/Link";
import {VideoStatusTag} from "../VideoStatusTag/VideoStatusTag";
import {Video} from "../../services/video/types";


export const VideoListElement: React.FC<{ video: Video }> = ({video}) => {
    const imgSrc = video.thumbnail_url;
    const imgFallback = "https://user-images.githubusercontent.com/101482/29592647-40da86ca-875a-11e7-8bc3-941700b0a323.png"
    const targetUrl = `/videos/${video.hash}/`

    return (
        <div style={{"maxWidth": "620px"}}>
            <Link to={targetUrl}>
                <Card>
                    <StyledContents>
                        <div style={{
                            "display": "flex",
                            "alignItems": "center",
                            "justifyContent": "space-between"
                        }}>
                            <MonoParagraphMedium>{video.hash}</MonoParagraphMedium>
                            <VideoStatusTag status={video.status}/>
                        </div>

                        <div style={{"marginLeft": "40px"}}>
                            <img
                                src={imgSrc || imgFallback}
                                style={{maxHeight: "220px", maxWidth: "95%"}}
                            />
                        </div>
                    </StyledContents>
                    <StyledContents>
                        <ParagraphSmall>{`Created ${formatDistanceToNow(video.created)} ago`}</ParagraphSmall>
                    </StyledContents>
                </Card>
            </Link>
        </div>
    );
}