import {PlaylistStatus, VideoStatus} from "./enums";
import { KJUR } from 'jsrsasign';


export interface VideoPlaylist {
    quality: string;
    status: PlaylistStatus;
    url: string;
    processing_progress: number;
    processing_eta: Date | null;
}

export interface UploadLocation {
    tus: string;
    simple_upload: string
}

export interface Video {
    hash: string;
    status: VideoStatus;
    require_signed_urls: boolean;
    signed_urls_secret_key: string;
    master_hls_manifest_url: string | null;
    thumbnail_url: string | null;
    upload_location: UploadLocation | null;
    playlists: VideoPlaylist[];
    created: Date;
}


export const getMasterPlaylistUrl = (video: Video) : string | null => {
    if (!video.master_hls_manifest_url) {
        return video.master_hls_manifest_url;
    }

    if (video.require_signed_urls) {
        const payload = {
            //"expiry": Math.floor(Date.now() / 1000) + 30,
            "expiry": 0,
        };
        const header = { alg: "HS256", typ: "JWT" };
        const signature = KJUR.jws.JWS.sign("HS256", header, payload, { utf8: video.signed_urls_secret_key });

        return `${video.master_hls_manifest_url}?signature=${signature}`;
    }
    return video.master_hls_manifest_url;
}
