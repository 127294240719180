import {Constant} from "../consts";
import {useAuthStore} from "../services/auth/state";
import {logout} from "../services/auth/actions";

export class APIClient {
    baseUrl: string

    constructor() {
        this.baseUrl = Constant.BACKEND_API_BASE_URL;
    }

    buildUrl(path: string): string{
        return this.baseUrl + path;
    }

    async makeRequest(url: string, method: string, body: string|null = null, auth: boolean = false){
        const fullUrl = this.buildUrl(url);

        const headers: { [key: string]: string } = {
            'Content-Type': 'application/json'
        };

        if (auth) {
            const authStore = useAuthStore.getState()
            if(authStore.auth == null){
                console.log("logging out because not logged in")
                logout();
                throw Error("logout");
            }
            headers['Authorization'] = `Token ${authStore.auth.token}`;
            headers['Platform'] = authStore.auth.platformId.toString();
        }

        console.log(`BackendAPI ${method} request ${fullUrl} with payload ${body}`)

        return await fetch(fullUrl, {
            method: method,
            headers: headers,
            body: body,
        });
    };

    async get(url: string, auth: boolean = false){
        return await this.makeRequest(url, "GET", null, auth);
    }

    async post(url: string, payload: Object|null, auth: boolean = false){
        const body = payload ? JSON.stringify(payload) : null;
        return await this.makeRequest(url, 'POST', body, auth);
    }

    async put(url: string, payload: Object|null, auth: boolean = false){
        const body = payload ? JSON.stringify(payload) : null;
        return await this.makeRequest(url, 'PUT', body, auth);
    }
}
