export enum VideoStatus {
    NEW = "new",
    UPLOADING = "uploading",
    WAITING = "waiting",
    PROCESSING = "processing",
    PARTIALLY_AVAILABLE = "partially_available",
    READY = "ready"
}

export enum PlaylistStatus {
    PROCESSING = "processing",
    READY = "ready"
}


export function getVideoStatusDescription(status: VideoStatus): string {
    switch(status) {
        case VideoStatus.NEW: return "New";
        case VideoStatus.UPLOADING: return "Uploading...";
        case VideoStatus.WAITING: return "Queued";
        case VideoStatus.PROCESSING: return "Processing";
        case VideoStatus.PARTIALLY_AVAILABLE: return "Partially available";
        case VideoStatus.READY: return "Ready";
        default: return "Unknown status";
    }
}


export enum UploadMode {
    TUS = "tus",
    SIMPLE = "simple"
}