import {ALIGN, HeaderNavigation, StyledNavigationItem, StyledNavigationList} from "baseui/header-navigation";
import React from "react";
import {UserAvatar} from "../UserAvatar/UserAvatar";
import {UploadManager} from "../UploadManager/UploadManager";
import {PlatformSelector} from "../PlatformSelector/PlatformSelector";

export const Navbar: React.FC<{ color: string }> = ({color}) => {

    return (
        <HeaderNavigation
            overrides={{
                Root: {
                    style: {
                        backgroundColor: color,
                    },
                },
            }}
        >
            <StyledNavigationList $align={ALIGN.left}>
                <StyledNavigationItem>
                    <PlatformSelector/>
                </StyledNavigationItem>
            </StyledNavigationList>
            <StyledNavigationList $align={ALIGN.center}/>
            <StyledNavigationList $align={ALIGN.right}>
                <StyledNavigationItem>
                    <UploadManager/>
                </StyledNavigationItem>
                <StyledNavigationItem/>
                <StyledNavigationItem>
                    <UserAvatar/>
                </StyledNavigationItem>
                <StyledNavigationItem/>
            </StyledNavigationList>
        </HeaderNavigation>
    );
}
