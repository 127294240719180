import localforage from "localforage";


export class UploadsCache {
    _store: LocalForage

    constructor() {
        this._store = localforage.createInstance({
            driver: localforage.INDEXEDDB,
            name: 'granada_indexeddb',
            version: 1.0,
            storeName: 'uploads-cache',
            description: 'Uploads Cache'
        });
    }

    _cacheKey(uploadUrl: string) {
        return "fileCache::" + uploadUrl
    }

    // Method to get an item from the cache
    async get(uploadUrl: string): Promise<File | null> {
        return this._store.getItem(this._cacheKey(uploadUrl)).then(
            value => {
                if (!value) {
                    return null;
                }
                return value as File;
            }
        )
    }

    // Method to set an item in the cache
    async set(uploadUrl: string, file: File) {
        try {
            await this._store.setItem(this._cacheKey(uploadUrl), file);
        } catch (error) {
            console.error('Error setting item in UploadsCache:', error);
        }
    }

    async remove(uploadUrl: string){
        await this._store.removeItem(this._cacheKey(uploadUrl));
    }
}
