import {useAuthStore} from "./state";
import {Platform, User} from "./types";
import React from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {APIClient} from "../../api/APIClient";

interface UseLoginActionResult {
    isLoading: boolean
    success: boolean
    errorMessage: string | null
    use: (email: string, password: string) => void
}


const getCurrentPlatformId = (platforms: Platform[]) => {
    const lastPlatformId = useAuthStore.getState().lastPlatformId;
    if (!lastPlatformId) {
        return platforms[0]
    }

    const dataReduced = platforms.filter((platform) => platform.id === lastPlatformId)
    if (dataReduced.length === 0) {
        return platforms[0]
    }
    return dataReduced[0]
}


export const useLoginAction = (): UseLoginActionResult => {
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [success, setSuccess] = React.useState<boolean>(false);

    const apiClient = new APIClient();

    const mutation = useMutation((data: { email: string, password: string }) => {
        return apiClient.post('api/v1/auth/login/', data);
    }, {
        onSuccess: (response, variables, context) => {
            response.json().then(data => {
                if (!response.ok) {
                    const errorMsg: string = data.non_field_errors[0];
                    setErrorMessage(errorMsg);
                    return;
                }
                setSuccess(true);
                const token: string = data.token.key;
                const user: User = {email: data.user.email, id: data.user.id}
                const platformId = getCurrentPlatformId(data.platforms as Platform[]).id

                useAuthStore.setState((prevState) => {
                    return {
                        lastPlatformId: platformId,
                        auth: {
                            user: user,
                            token: token,
                            platformId: platformId,
                        },
                    }
                })

            })
        },
    })

    const use = (email: string, password: string) => {
        setSuccess(false);
        mutation.mutate({
            email: email,
            password: password,
        })
    }

    return {
        isLoading: mutation.isLoading,
        success: success,
        use: use,
        errorMessage: errorMessage,
    };
}

export const logout = (): void => {
    useAuthStore.setState((state) => ({auth: null}))
}


export const usePlatforms = () => {
    const apiClient = new APIClient();

    return useQuery<[Platform], Error>(
        ["platforms"],
        () => apiClient.get(`api/v1/my-platforms/`, true).then(
            data => data.json()
        ).then(
            data => data.map((v: any) => v as Platform)
        ),
        {
            staleTime: Infinity, // will be fetched only once
        }
    );
};


export const useCurrentPlatform = () => {
    const {data: platforms} = usePlatforms();
    const auth = useAuthStore().auth;
    const changePlatformAction = useChangeCurrentPlatformAction();

    if (!platforms) {
        return null;
    }

    if (auth == null) {
        return null;
    }

    const reducedPlatforms = platforms.filter(platform => platform.id === auth.platformId)
    if (reducedPlatforms.length === 0) {
        changePlatformAction.change(platforms[0].id)
        return platforms[0];
    }

    return reducedPlatforms[0] as Platform
}


export const useChangeCurrentPlatformAction = () => {
    const {data: platforms} = usePlatforms();
    const queryClient = useQueryClient();

    const changePlatform = (platformId: number) => {
        if (!platforms) {
            return;
        }

        const reducedPlatforms = platforms.filter(platform => platform.id === platformId)
        if (reducedPlatforms.length === 0) {
            return;
        }

        const platform = reducedPlatforms[0]

        console.log("Changing platform to", platform)
        useAuthStore.setState((prevState) => {
            if (!prevState.auth) {
                return prevState
            }

            return {
                ...prevState,
                lastPlatformId: platform.id,
                auth: {
                    ...prevState.auth,
                    platformId: platform.id
                }
            }
        })

        queryClient.removeQueries();
    }

    return {
        change: changePlatform,
    }
}
