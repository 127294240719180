import {Heading, HeadingLevel} from "baseui/heading";

import React from "react";
import {Button} from "baseui/button";
import {Delete, Plus} from "baseui/icon";
import {CenteredDiv} from "../../../components/CenteredDiv/CenteredDiv";
import {ListItem, ListItemLabel} from 'baseui/list';
import {useStyletron} from 'baseui';
import {Badge, COLOR, HIERARCHY, SHAPE} from "baseui/badge";
import {Modal, ModalBody, ModalButton, ModalFooter, ModalHeader} from "baseui/modal";
import {Input} from "baseui/input";
import {FormControl} from "baseui/form-control";
import {Select, Value} from "baseui/select";


const AddTeamMemberModal: React.FC<{
    isOpen: boolean,
    close: () => void,
}> = ({isOpen, close}) => {
    const roles = [
        {label: "Admin", id: "admin"},
        {label: "Editor", id: "editor"},
        {label: "Viewer", id: "viewer"},
    ];

    const [v, setV] = React.useState<Value>([roles[0]]);

    return (
        <Modal onClose={close} isOpen={isOpen}>
            <ModalHeader>Add a new team member</ModalHeader>
            <ModalBody>
                <FormControl
                    label={"Name"}
                >
                    <Input
                        // value={"John doe"}
                        placeholder={"John Doe"}
                    />
                </FormControl>

                <FormControl
                    label={"Email"}
                >
                    <Input
                        // value={"John doe"}
                        placeholder={"johndoe@gmail.com"}
                        type={"email"}
                    />
                </FormControl>

                <FormControl
                    label={"Role"}
                >
                    <Select
                        options={roles}
                        onChange={({value}) => setV(value)}
                        value={v}
                        clearable={false}
                        searchable={false}
                        placeholder="Select role"
                    />
                </FormControl>

            </ModalBody>
            <ModalFooter>
                <ModalButton kind="tertiary" onClick={close}>
                    Cancel
                </ModalButton>
                <ModalButton onClick={close}>Send invite</ModalButton>
            </ModalFooter>
        </Modal>
    )
}


export function TeamPage() {
    const [css] = useStyletron();

    const team = [
        {
            "name": "Jane Smith",
            "email": "janesmith@example.com",
            "role": "Owner",
            "removable": false,
        },
        {
            "name": "John Doe",
            "email": "johndoe@gmail.com",
            "role": "Admin",
            "removable": true,
        },
        {
            "name": "Alice Johnson",
            "email": "alicej@example.com",
            "role": "Editor",
            "removable": true,
        },
        {
            "name": "Bob Williams",
            "email": "bobw@example.com",
            "role": "Viewer",
            "removable": true,
        },
        {
            "name": "Charlie Brown",
            "email": "charlieb@example.com",
            "role": "Editor",
            "removable": true,
        },
        {
            "name": "Diana Ross",
            "email": "dianar@example.com",
            "role": "Admin",
            "removable": true,
        },
        {
            "name": "Evan Wright",
            "email": "evanw@example.com",
            "role": "Viewer",
            "removable": true,
        },
        {
            "name": "Fiona Green",
            "email": "fionag@example.com",
            "role": "Editor",
            "removable": true,
        },
        {
            "name": "George King",
            "email": "georgek@example.com",
            "role": "Viewer",
            "removable": true,
        },
        {
            "name": "Hannah Lee",
            "email": "hannahl@example.com",
            "role": "Admin",
            "removable": true,
        }
    ];

    const [modalIsisOpen, setModalIsisOpen] = React.useState(false);
    const closeModal = () => {
        setModalIsisOpen(false);
    }

    const openModal = () => {
        setModalIsisOpen(true);
    }

    return (
        <CenteredDiv>
            <AddTeamMemberModal isOpen={modalIsisOpen} close={closeModal}/>
            <div style={{"width": "80%", "marginTop": "50px"}}>
                <HeadingLevel>
                    <div
                        style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}
                    >
                        <Heading>
                            Team
                        </Heading>
                        <Button
                            startEnhancer={() => <Plus size={24}/>}
                            onClick={openModal}
                        >
                            Team member
                        </Button>
                    </div>

                </HeadingLevel>

                <div>
                    <ul
                        className={css({
                            width: '100%',
                            paddingLeft: 0,
                            paddingRight: 0,
                        })}
                    >
                        {team.map(m => (
                            <ListItem
                                endEnhancer={() => (
                                    <React.Fragment>
                                        <Badge
                                            content={m.role}
                                            shape={SHAPE.pill}
                                            color={COLOR.accent}
                                            hierarchy={HIERARCHY.secondary}
                                        />
                                        <div style={{width: '18px'}}/>
                                        <Button
                                            shape="round"
                                            size="compact"
                                            kind="primary"
                                            colors={{backgroundColor: "rgb(99 99 99)", color: "white"}}
                                            disabled={!m.removable}
                                        >
                                            <Delete/>
                                        </Button>
                                    </React.Fragment>
                                )}
                            >
                                <ListItemLabel>{m.name}</ListItemLabel>
                                <ListItemLabel>{m.email}</ListItemLabel>
                            </ListItem>
                        ))}
                    </ul>
                </div>

            </div>
        </CenteredDiv>
    )
}