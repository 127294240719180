import React, {useEffect, useState} from "react";
import {VideoPlaylist} from "../../services/video/types";
import {PlaylistStatus} from "../../services/video/enums";
import {formatDistanceToNow} from "date-fns";
import {ProgressBar} from "baseui/progress-bar";

export const PlaylistProcessingProgressBar: React.FC<{ playlist: VideoPlaylist }> = ({playlist}) => {
    const [displayETA, setDisplayETA] = useState(false);
    const progress = Math.round(playlist.processing_progress);
    const infinite = (
        progress === 0
    ) || (
        progress === 100 && playlist.status === PlaylistStatus.PROCESSING
    )

    const getProgressLabel = (value: number) => {
        if (playlist.status === PlaylistStatus.READY) {
            return "Your video is ready!"
        }

        if (value === 0) {
            return "Processing is starting..."
        }

        if (value === 100) {
            return "Almost there..."
        }

        const progressString = `${value}% processed...`

        if (playlist.processing_eta == null) {
            return progressString
        }

        const etaString = `About ${formatDistanceToNow(playlist.processing_eta)} left...`

        return displayETA ? etaString : progressString;
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDisplayETA((prev) => !prev);
        }, 6000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <ProgressBar
            value={progress}
            getProgressLabel={getProgressLabel}
            showLabel={true}
            infinite={infinite}
        />
    )
}