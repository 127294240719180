import {Heading, HeadingLevel} from "baseui/heading";

import React from "react";
import {CenteredDiv} from "../../../components/CenteredDiv/CenteredDiv";
import {CodeFragment} from "../../../components/CodeFragment/CodeFragment";
import {Select, Value} from "baseui/select";
import {Block} from "baseui/block";
import {FormControl} from "baseui/form-control";
import {Checkbox} from "baseui/checkbox";
import {Button} from "baseui/button";
import { BiSave } from "react-icons/bi";


export function ConfigurationPage() {
    const apiSecret = "secret_6213c554131302f2f925655018c25e9280be84a424be522b9dd09942b1afbae1"
    const [codecValue, setCodecValue] = React.useState<Value>([{label: 'AV1', code: 'av1'}]);

    const availableQualities = ["360p", "480p", "720p", "1080p"];

    const [selectedQualities, setSelectedQualities] = React.useState(availableQualities);

    const handleQualityChange = (quality: string) => {
        if (selectedQualities.includes(quality)) {
            setSelectedQualities(selectedQualities.filter(q => q !== quality));
        } else {
            setSelectedQualities([...selectedQualities, quality]);
        }
    };

    const [parallelEncoding, setParallelEncoding] = React.useState(true);

    const spaceBetween = 40;

    return (
        <CenteredDiv>
            <div style={{"width": "80%", "marginTop": "50px"}}>
                <HeadingLevel>
                    <div
                        style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}
                    >
                        <Heading>
                            Configuration
                        </Heading>

                    </div>

                </HeadingLevel>

                <div>
                    <div>
                        <CodeFragment
                            label="API Secret"
                            code={apiSecret}
                            isMasked={true}
                            maskStart={10}
                            maskEnd={apiSecret.length-8}
                        />
                    </div>

                    <div style={{marginTop: spaceBetween, maxWidth: 180}}>
                        <Block>
                            <FormControl
                                label={"Default video output codec"}
                                overrides={{
                                    ControlContainer: {
                                        style: {
                                            marginBottom: "4px",
                                        },
                                    },
                                }}
                            >
                                <Select
                                    options={[
                                        {label: 'AV1', code: 'av1'},
                                        {label: 'H264', code: 'h264'},
                                        {label: 'H265', code: 'h265'},
                                    ]}
                                    labelKey="label"
                                    valueKey="code"
                                    onChange={({value}) => setCodecValue(value)}
                                    value={codecValue}
                                    clearable={false}
                                />
                            </FormControl>
                        </Block>
                    </div>


                    <div style={{marginTop: spaceBetween}}>
                        <Block>
                            <FormControl
                                label={"Default video output resolutions"}
                                overrides={{
                                    ControlContainer: {
                                        style: {
                                            marginBottom: "4px",
                                        },
                                    },
                                }}
                            >
                                <div style={{display: "flex"}}>
                                    {availableQualities.map(q => (
                                        <div style={{marginRight: 50}}>
                                            <Checkbox
                                                key={q} // Added a key for each checkbox
                                                checked={selectedQualities.includes(q)}
                                                onChange={() => handleQualityChange(q)}
                                                disabled={selectedQualities.includes(q) && selectedQualities.length === 1}
                                            >
                                                {q}
                                            </Checkbox>
                                        </div>
                                    ))}

                                </div>
                            </FormControl>

                        </Block>
                    </div>

                    <div style={{marginTop: spaceBetween}}>
                        <Block>
                            <FormControl
                                label={"Video encoding speed"}
                                overrides={{
                                    ControlContainer: {
                                        style: {
                                            marginBottom: "4px",
                                        },
                                    },
                                }}
                            >
                                <Checkbox
                                    checked={parallelEncoding}
                                    onChange={() => setParallelEncoding(!parallelEncoding)}
                                >
                                    Parallel encoding (experimental)
                                </Checkbox>
                            </FormControl>

                        </Block>
                    </div>


                    <div style={{marginTop: spaceBetween}}>
                        <Button
                            startEnhancer={() => <BiSave />}
                        >
                            Save changes
                        </Button>
                    </div>

                </div>

            </div>
        </CenteredDiv>
    )
}