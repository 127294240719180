import {Heading, HeadingLevel} from "baseui/heading";
import {Button} from "baseui/button";
import React from "react";
import {ControlledInput} from "../../components/ControlledInput/ControlledInput";
import {EmailValidator, PasswordValidator} from "../../utils/validators";
import {useLoginAction} from "../../services/auth/actions";
import {CenteredDiv} from "../../components/CenteredDiv/CenteredDiv";
import {Banner, HIERARCHY, KIND} from "baseui/banner";


export function LoginPage() {
    const [email, setEmail] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");

    const loginAction = useLoginAction()

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log("onSubmit");
        loginAction.use(email, password);
    }

    return (
        <CenteredDiv>
            <HeadingLevel>
                <Heading>
                    Login
                </Heading>
                <div>
                    <form onSubmit={onSubmit}>
                        {
                            loginAction.errorMessage && (
                                <Banner
                                    hierarchy={HIERARCHY.high}
                                    kind={KIND.negative}
                                >
                                    {loginAction.errorMessage}
                                </Banner>
                            )
                        }
                        <ControlledInput
                            value={email}
                            onChange={setEmail}
                            type="email"
                            label="Your email"
                            validator={EmailValidator}
                        />
                        <ControlledInput
                            value={password}
                            onChange={setPassword}
                            type="password"
                            label="Password"
                            validator={PasswordValidator}
                        />
                        <Button
                            type="submit"
                            isLoading={loginAction.isLoading}
                        >
                            Login
                        </Button>
                    </form>

                </div>
            </HeadingLevel>
        </CenteredDiv>
    )
}