import {create} from "zustand";
import {persist} from "zustand/middleware";
import {Upload} from "tus-js-client";

export interface ManagedUpload {
    filename: string;
    uploadUrl: string;  // unique identifier
    videoHash: string;
    progress: number;
    completed: boolean;
    createdAt: number; // timestamp
    platformId: number;
    isPaused: boolean; // not persistent
    uploadInstance: Upload | null // not persistent
}

export interface UploadsState {
    uploads: Record<string, ManagedUpload>;
}

const defaultUploadsState : UploadsState = {
    uploads: {}
}

export const useUploadsStore = create<UploadsState>()(
    persist(
        (set, get) => defaultUploadsState,
        {
            name: "state::uploads",
            partialize: (state) => {
                // Creating a copy of the state with the `isPaused` field removed
                const uploads = Object.fromEntries(
                    Object.entries(state.uploads).map(([key, value]) => [
                        key,
                        {
                            ...value,
                            isPaused: true, // always save to persistent storage as true
                            uploadInstance: null, // don't save
                        }
                    ])
                );

                // Returning the state to be persisted
                return { ...state, uploads };
            }
        }
    )
)
