import {useStyletron} from "baseui";
import {Alert} from "baseui/icon";
import React from "react";
import {FormControl} from "baseui/form-control";
import {Input} from "baseui/input";
import {Validator, ValidatorResult} from "../../utils/validators";

function NegativeIcon() {
    const [css, theme] = useStyletron();
    return (
        <div
            className={css({
                display: 'flex',
                alignItems: 'center',
                paddingRight: theme.sizing.scale500,
                color: theme.colors.negative400,
            })}
        >
            <Alert size="18px"/>
        </div>
    );
}

interface ControlledInputProps {
    value: string;
    onChange: (newValue: string) => void;
    type: string;
    label: string;
    validator: Validator;
}

export const ControlledInput = (props: ControlledInputProps) => {
    const [isVisited, setIsVisited] = React.useState(false);
    const [validationResult, setValidationResult] = React.useState<ValidatorResult>(
        props.validator.validate(props.value)
    );
    const shouldShowError = !validationResult.isValid && isVisited;

    const onChange = (event: any) => {
        const value: string = event.target.value;
        setValidationResult(props.validator.validate(value));
        props.onChange(value);
    };

    return (
        <FormControl
            label={props.label}
            error={shouldShowError ? validationResult.errorMessage : null}
        >
            <Input
                value={props.value}
                onChange={onChange}
                onBlur={() => setIsVisited(true)}
                error={shouldShowError}
                overrides={shouldShowError ? {After: NegativeIcon} : {}}
                type={props.type}
            />
        </FormControl>
    )
}