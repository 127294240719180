import React from "react";
import {KIND, SIZE, Tag, VARIANT} from "baseui/tag";
import {getVideoStatusDescription, VideoStatus} from "../../services/video/enums";

export const VideoStatusTag: React.FC<{ status: VideoStatus }> = ({status}) => {
    const statusColorKind: any = {
        [VideoStatus.NEW]: KIND.neutral,
        [VideoStatus.UPLOADING]: KIND.warning,
        [VideoStatus.WAITING]: KIND.warning,
        [VideoStatus.PROCESSING]: KIND.warning,
        [VideoStatus.PARTIALLY_AVAILABLE]: KIND.positive,
        [VideoStatus.READY]: KIND.positive,
    }[status]
    const statusVariant = (statusColorKind === KIND.neutral) ? VARIANT.outlined : VARIANT.solid;

    return (
        <Tag
            closeable={false}
            kind={statusColorKind}
            variant={statusVariant}
            size={SIZE.medium}
            overrides={{
                Text: {
                    style: () => ({
                        maxWidth: 200
                    })
                }
            }}
        >
            {getVideoStatusDescription(status)}
        </Tag>
    );
}
