import {Video} from "./types";
import {APIClient} from "../../api/APIClient";
import {useMutation, useQuery} from "react-query";
import {UploadMode, VideoStatus} from "./enums";
import React, {useEffect} from "react";
import {Upload} from "tus-js-client";
import {postFile} from "../../api/tools";
import {uploadsCache} from "../../pages/App/App";
import {ManagedUpload, useUploadsStore} from "./state";
import {useCurrentPlatform} from "../auth/actions";

function parseVideoData(data: any): Video {
    return {
        ...data,
        created: new Date(data.created),
        playlists: data.playlists.map((playlist: any) => ({
            ...playlist,
            processing_eta: playlist.processing_eta ? new Date(playlist.processing_eta) : null,
        })),
    };
}


export const useVideo = (videoHash: string, refreshInterval: number) => {
    const apiClient = new APIClient();

    return useQuery<Video, Error>(
        ["video", videoHash],
        () => apiClient.get(`api/v1/video/${videoHash}/`, true).then(data => data.json()).then(data => parseVideoData(data)),
        {
            staleTime: 1000 * 60 * 5, // data is considered fresh for 5 minutes
            refetchInterval: (video) => video?.status !== VideoStatus.READY ? refreshInterval : false,
        }
    );
};


export const useVideos = (refreshInterval: number | null) => {
    const apiClient = new APIClient();

    return useQuery<[Video], Error>(
        ["videos"],
        () => apiClient.get(`api/v1/videos/`, true).then(
            data => data.json()
        ).then(
            data => data.map((v: any) => parseVideoData(v))
        ),
        {
            refetchInterval: () => refreshInterval === null ? false : refreshInterval,
        }
    );
};


export const useCreateVideo = () => {
    const [success, setSuccess] = React.useState<boolean>(false);
    const apiClient = new APIClient();

    const mutation = useMutation((data: { size: number, filename: string, filetype: string, signedUrl: boolean }) => {
        return apiClient.post(
            'api/v1/videos/',
            {
                "size": data.size,
                "filename": data.filename,
                "filetype": data.filetype,
                "require_signed_urls": data.signedUrl,
            },
            true,
        );
    })

    const use = (size: number, filename: string, filetype: string, signedUrl: boolean): Promise<Video> => {
        setSuccess(false);
        return new Promise((resolve, reject) => {
                mutation.mutate({
                    size: size,
                    filename: filename,
                    filetype: filetype,
                    signedUrl: signedUrl,
                }, {
                    onSuccess: (response) => {
                        response.json().then(
                            data => parseVideoData(data)
                        ).then(
                            data => {
                                setSuccess(true);
                                resolve(data);
                            }
                        )
                    },
                    onError: (error) => {
                        setSuccess(false);
                        reject(error);
                    }
                })
            }
        )
    }

    return {
        isLoading: mutation.isLoading,
        success: success,
        use: use,
    };
};


export const useUpdateVideo = () => {
    const [success, setSuccess] = React.useState<boolean>(false);
    const apiClient = new APIClient();

    const mutation = useMutation((data: { videoHash: string, signedUrl: boolean }) => {
        return apiClient.put(
            `api/v1/video/${data.videoHash}/`,
            {"require_signed_urls": data.signedUrl},
            true,
        );
    })

    const use = (video: Video, signedUrl: boolean): Promise<Video> => {
        setSuccess(false);
        return new Promise((resolve, reject) => {
                mutation.mutate({
                    videoHash: video.hash,
                    signedUrl: signedUrl,
                }, {
                    onSuccess: (response) => {
                        response.json().then(
                            data => parseVideoData(data)
                        ).then(
                            data => {
                                setSuccess(true);
                                resolve(data);
                            }
                        )
                    },
                    onError: (error) => {
                        setSuccess(false);
                        reject(error);
                    }
                })
            }
        )
    }

    return {
        isLoading: mutation.isLoading,
        success: success,
        use: use,
    };
};


const makeTusUpload = (
    uploadUrl: string,
    file: File,
) => {
    const cleanup = () => {
        uploadsCache.remove(uploadUrl).then(() => {
            console.log("removed from indexeddb", uploadUrl)
        })
    }

    return new Upload(file, {
        uploadUrl: uploadUrl,
        // Retry delays will enable tus-js-client to automatically retry on errors
        retryDelays: [0, 3000, 5000, 10000, 20000],
        // Attach additional metadata about the file for the server
        metadata: {
            filename: file.name,
            filetype: file.type,
        },
        chunkSize: 10 * 1024 * 1024,
        // Custom fingerprint function
        fingerprint: function (file, options) {
            // Concatenating file properties with the upload URL
            const fingerprintValue = [
                file.name,
                file.type,
                file.size,
                options?.uploadUrl
            ].join('-');

            // Return a promise resolving to the fingerprint value
            return Promise.resolve(fingerprintValue);
        },
        // Callback for errors which cannot be fixed using retries
        onError: function (error: any) {
            console.log('Failed because: ' + error)
        },
        // Callback for reporting upload progress
        onProgress: function (bytesUploaded: number, bytesTotal: number) {
            const percentage = (bytesUploaded / bytesTotal) * 100
            console.log(bytesUploaded, bytesTotal, percentage.toFixed(2) + '%')

            useUploadsStore.setState(prevState => ({
                uploads: {
                    ...prevState.uploads,
                    [uploadUrl]: {
                        ...prevState.uploads[uploadUrl],
                        progress: percentage,
                    }
                }
            }))
        },
        // Callback for once the upload is completed
        onSuccess: function () {
            console.log('File uploaded', uploadUrl)
            cleanup()
            useUploadsStore.setState(prevState => ({
                uploads: {
                    ...prevState.uploads,
                    [uploadUrl]: {
                        ...prevState.uploads[uploadUrl],
                        completed: true,
                    }
                }
            }))
        },
    })

}


export const useUploadVideo = () => {
    const [success, setSuccess] = React.useState<boolean | null>(null);
    const [percentage, setPercentage] = React.useState(0);
    const [tusUploadUrl, setTusUploadUrl] = React.useState<string | null>(null);
    const uploadManager = useUploadManager();

    useEffect(() => {
        if (tusUploadUrl === null) {
            return;
        }

        const upload = uploadManager.uploads[tusUploadUrl];
        if (!upload) {
            return;
        }

        setPercentage(upload.progress)
        if (upload.completed) {
            setSuccess(upload.completed)
        }
    }, [tusUploadUrl, uploadManager])

    const use = (video: Video, file: File, uploadMode: UploadMode) => {
        if (!video.upload_location) {
            throw Error
        }

        if (uploadMode === UploadMode.SIMPLE) {
            postFile(
                video.upload_location.simple_upload,
                file,
                (percentage: number) => {
                    setPercentage(percentage);
                },
            ).then(
                data => {
                    setSuccess(true);
                }
            )
        } else if (uploadMode === UploadMode.TUS) {
            const uploadUrl = video.upload_location.tus;
            setTusUploadUrl(uploadUrl);
            uploadManager.start(file, uploadUrl, video.hash)
        } else {
            throw Error("Not implemented")
        }
    }

    return {
        percentage: percentage,
        success: success,
        use: use,
    };
};


export const useUploadManager = () => {
    const uploadsStore = useUploadsStore();
    const currentPlatform = useCurrentPlatform();

    const getOrCreateUpload = (uploadUrl: string, file: File): Upload => {
        let uploadInstance = uploadsStore.uploads[uploadUrl]?.uploadInstance
        if (uploadInstance) {
            return uploadInstance
        }
        console.log("getOrCreateUpload create")
        uploadInstance = makeTusUpload(uploadUrl, file)
        useUploadsStore.setState(prevState => ({
            uploads: {
                ...prevState.uploads,
                [uploadUrl]: {
                    ...prevState.uploads[uploadUrl],
                    uploadInstance: uploadInstance,
                }
            }
        }))
        return uploadInstance;
    }

    const start = (file: File, uploadUrl: string, videoHash: string) => {
        if(!currentPlatform){
            return;
        }

        uploadsCache.set(uploadUrl, file).then(() => {
            console.log("stored in indexeddb", uploadUrl)
            useUploadsStore.setState(prevState => ({
                uploads: {
                    ...prevState.uploads,
                    [uploadUrl]: {
                        filename: file.name,
                        uploadUrl: uploadUrl,
                        videoHash: videoHash,
                        progress: 0,
                        isPaused: false,
                        completed: false,
                        platformId: currentPlatform.id,
                        createdAt: Date.now(),
                        uploadInstance: null
                    }
                }
            }))
            const upload = getOrCreateUpload(uploadUrl, file)
            upload.start()
        })
    }

    const resume = (uploadUrl: string) => {
        uploadsCache.get(uploadUrl).then(file => {
            if (!file) {
                return;
            }
            const upload = getOrCreateUpload(uploadUrl, file)
            useUploadsStore.setState(prevState => ({
                uploads: {
                    ...prevState.uploads,
                    [uploadUrl]: {
                        ...prevState.uploads[uploadUrl],
                        isPaused: false,
                    }
                }
            }))
            upload.start()
        })
    }

    const pause = (uploadUrl: string) => {
        uploadsCache.get(uploadUrl).then(file => {
            if (!file) {
                return;
            }
            const upload = getOrCreateUpload(uploadUrl, file)
            useUploadsStore.setState(prevState => ({
                uploads: {
                    ...prevState.uploads,
                    [uploadUrl]: {
                        ...prevState.uploads[uploadUrl],
                        isPaused: true,
                    }
                }
            }))
            upload.abort()
        })
    }

    const remove = (uploadUrl: string) => {
        useUploadsStore.setState(prevState => ({
            uploads: Object.fromEntries(
                Object.entries(prevState.uploads).filter(([key, value]) => value.uploadUrl !== uploadUrl)
            )
        }))
    }

    const uploads: Record<string, ManagedUpload> = Object.entries(uploadsStore.uploads)
        .filter(([key, managedUpload]) => managedUpload.platformId === currentPlatform?.id)
        .reduce((acc, [key, managedUpload]) => {
            acc[key] = managedUpload;
            return acc;
        }, {} as Record<string, ManagedUpload>);
    const uploadsList = Object.values(uploads)
    const isCurrentlyUploading = uploadsList.some(upload => (!upload.isPaused && !upload.completed));
    const hasPendingUploads = uploadsList.some(upload => !upload.completed);

    return {
        uploads: uploads,
        uploadsList: uploadsList.sort((a, b) => b.createdAt - a.createdAt),
        count: uploadsList.length,
        isCurrentlyUploading: isCurrentlyUploading,
        hasPendingUploads: hasPendingUploads,
        resume: resume,
        pause: pause,
        start: start,
        remove: remove,
    }
}
