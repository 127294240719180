import React from "react";
import {Player, Hls, DefaultUi, Spinner, DefaultControls} from '@vime/react';
import '@vime/core/themes/default.css';


export const VideoPlayer: React.FC<{
    master_playlist_url: string,
    thumbnail_url: string
}> = ({master_playlist_url, thumbnail_url}) => {
    const hlsConfig = {
        maxBufferSize: 60 * 1024 * 1024, // 60MB
        maxBufferLength: 30, // 30 seconds
        maxMaxBufferLength: 60, // 60 seconds for VOD
    };

    return (
        <div style={{maxWidth: 800, display: "flex", justifyContent: "center"}}>
            <div style={{width: "100%"}}>
                <Player>
                    <Hls version="1.4.14" config={hlsConfig} poster={thumbnail_url}>
                        <source data-src={master_playlist_url} type="application/x-mpegURL"/>
                    </Hls>

                    <DefaultUi noControls>
                        <Spinner/>
                        <DefaultControls hideOnMouseLeave activeDuration={2000}/>
                    </DefaultUi>
                </Player>
            </div>
        </div>
    );
}
