import {Button} from "baseui/button";
import {Badge, COLOR, HIERARCHY, HintDot, SHAPE} from "baseui/badge";
import React from "react";
import {FiUpload} from "react-icons/fi";
import {PLACEMENT, StatefulPopover} from "baseui/popover";
import {Block} from "baseui/block";
import {LabelSmall} from "baseui/typography";
import {ProgressBarRounded} from "baseui/progress-bar";
import {CgClose, CgPlayButtonO, CgPlayPauseO} from "react-icons/cg";
import {useUploadManager} from "../../services/video/actions";
import {ManagedUpload} from "../../services/video/state";
import {Spinner} from "baseui/spinner";
import {withStyle} from "baseui";


const SmallSpinner = withStyle(Spinner, {
    width: '10px',
    height: '10px',
    borderLeftWidth: '2px',
    borderRightWidth: '2px',
    borderTopWidth: '2px',
    borderBottomWidth: '2px',
    cursor: "pointer",
});


export const UploadManager = () => {
    const uploadManager = useUploadManager();

    const btn = (
        <Button
            shape="circle"
            size="compact"
            kind="secondary"
        >
            {uploadManager.isCurrentlyUploading ? (
                <SmallSpinner/>
            ) : (
                <FiUpload/>
            )}
        </Button>
    )

    return (
        <StatefulPopover
            content={() => (
                <Block>
                    {uploadManager.count === 0 ? (
                        <div style={{padding: 20}}>
                            <LabelSmall>No pending uploads 🎉</LabelSmall>
                        </div>
                    ) : (
                        <div style={{padding: "10px 10px 20px 20px"}}>
                            {uploadManager.uploadsList.map(upload => (
                                <PendingUploadComponent
                                    upload={upload}
                                    key={upload.uploadUrl}
                                />
                            ))}
                        </div>
                    )}

                </Block>
            )}
            placement={PLACEMENT.bottom}
            showArrow={true}
            returnFocus={true}
            autoFocus={true}
        >
            <div>
                {(uploadManager.hasPendingUploads && !uploadManager.isCurrentlyUploading) ? (
                    <HintDot>
                        {btn}
                    </HintDot>
                ) : btn}
            </div>
        </StatefulPopover>
    )
}

const PendingUploadComponent: React.FC<{ upload: ManagedUpload }> = ({upload}) => {
    const uploadManager = useUploadManager();

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 10,
                height: 36,
                minWidth: 184,
            }}
        >

            <LabelSmall>{upload.filename}</LabelSmall>

            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{width: 12}}></div>
                {upload.completed ? (
                    <Badge
                        content="Done"
                        hierarchy={HIERARCHY.secondary}
                        shape={SHAPE.rectangle}
                        color={COLOR.positive}
                    />
                ) : (
                    !!upload.progress && (
                        <ProgressBarRounded
                            progress={upload.progress / 100}
                            size={"small"}
                        />
                    )
                )}

                <React.Fragment>
                    <div style={{width: 6}}></div>
                    <Button
                        shape={"circle"}
                        size={"compact"}
                        kind={"secondary"}
                        onClick={
                            () => {
                                if (upload.completed) {
                                    uploadManager.remove(upload.uploadUrl);
                                    return;
                                }

                                if (upload.isPaused) {
                                    console.log("uploadManager.resume(upload.uploadUrl)")
                                    uploadManager.resume(upload.uploadUrl)
                                } else {
                                    console.log("uploadManager.pause(upload.uploadUrl)")
                                    uploadManager.pause(upload.uploadUrl)
                                }
                            }
                        }
                    >
                        {
                            upload.completed ? <CgClose size={16}/> :
                                upload.isPaused ? <CgPlayButtonO size={20}/> :
                                    <CgPlayPauseO size={20}/> // default
                        }
                    </Button>
                </React.Fragment>

            </div>
        </div>
    )
}