import {Heading, HeadingLevel} from "baseui/heading";
import {VideoListElement} from "../../components/VideoListElement/VideoListElement";
import {useVideos} from "../../services/video/actions";
import {Spinner} from "baseui/spinner";
import React from "react";
import {Button} from "baseui/button";
import {Plus} from "baseui/icon";
import {Link} from "../../components/Link/Link";
import videoFilesSvg from '../../resources/video_files.svg';
import {LabelLarge} from "baseui/typography";
import {CenteredDiv} from "../../components/CenteredDiv/CenteredDiv";


export function VideosPage() {
    const {data: videos, isLoading, isError, error, refetch} = useVideos(5000);

    return (
        <CenteredDiv>
            <div style={{"width": "80%", "marginTop": "50px"}}>
                <HeadingLevel>
                    <div
                        style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}
                    >
                        <Heading>
                            Videos
                        </Heading>
                        <Link to={"/upload"}>
                            <Button
                                startEnhancer={() => <Plus size={24}/>}
                            >
                                New video
                            </Button>
                        </Link>
                    </div>

                </HeadingLevel>

                {(videos === undefined) ? (
                    <div style={{display: "flex", justifyContent: "center", paddingTop: 300}}>
                        <Spinner $size="100px" $borderWidth="6px"/>
                    </div>
                ) : (
                    <div>
                        { !videos.length ? (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "35%",
                                    maxWidth: 400,
                                    marginTop: 80,
                                }}>
                                    <img src={videoFilesSvg}/>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: 20,
                                    }}>
                                        <LabelLarge>No videos yet!</LabelLarge>
                                    </div>
                                </div>
                            </div>
                        ) : videos.map(video => (
                            <div style={{"width": "60%", "marginTop": "20px"}} key={video.hash}>
                                <div style={{marginBottom: 30}}>
                                    <VideoListElement video={video}/>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </CenteredDiv>
    )
}