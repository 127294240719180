import {Heading, HeadingLevel} from "baseui/heading";
import React from "react";
import {Button, KIND, SIZE} from "baseui/button";
import {ArrowLeft} from "baseui/icon";
import {Link} from "../../components/Link/Link";
import {FileUpload} from "../../components/FileUpload/FileUpload";
import {ALIGN, Radio, RadioGroup} from "baseui/radio";
import {FormControl} from "baseui/form-control";
import {Checkbox} from "baseui/checkbox";
import {useCreateVideo, useUploadVideo} from "../../services/video/actions";
import {Video} from "../../services/video/types";
import {UploadMode} from "../../services/video/enums";
import {Navigate} from "react-router-dom";
import {CenteredDiv} from "../../components/CenteredDiv/CenteredDiv";


export function UploadPage() {
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const [uploadMethod, setUploadMethod] = React.useState(UploadMode.SIMPLE);
    const [signedUrlsRequired, setSignedUrlsRequired] = React.useState(false);

    const [formIsDisabled, setFormIsDisabled] = React.useState(false);
    const [video, setVideo] = React.useState<Video | null>(null);

    const createVideoAction = useCreateVideo();
    const uploadVideoAction = useUploadVideo();

    const onSubmit = async () => {
        if (!selectedFile) {
            return;
        }

        setFormIsDisabled(true);
        const video = await createVideoAction.use(selectedFile.size, selectedFile.name, selectedFile.type, signedUrlsRequired);
        setVideo(video);

        if (!video.upload_location){
            console.log("no video.upload_location")
            return;
        }
        uploadVideoAction.use(video, selectedFile, uploadMethod);
    }

    if (uploadVideoAction.success && !!video) {
        const redirectUrl = `/videos/${video.hash}/`;
        return <Navigate to={redirectUrl}/>
    }

    return (
        <CenteredDiv>
            <div style={{"width": "80%", "marginTop": "50px"}}>
                <div style={{marginBottom: 12}}>
                    <Link to={"/videos"}>
                        <Button
                            kind={KIND.tertiary}
                            size={SIZE.compact}
                            startEnhancer={() => <ArrowLeft size={24}/>}
                        >
                            All videos
                        </Button>
                    </Link>
                </div>
                <HeadingLevel>
                    <Heading>
                        Upload a new video
                    </Heading>
                </HeadingLevel>

                <div>
                    <FormControl label="Upload method" disabled={formIsDisabled}>
                        <RadioGroup
                            value={uploadMethod}
                            onChange={e => setUploadMethod(e.currentTarget.value as UploadMode)}
                            name="upload_method"
                            align={ALIGN.vertical}
                        >
                            <Radio
                                value={UploadMode.SIMPLE}
                                description="Single HTTP request"
                            >Simple HTTP upload</Radio>
                            <Radio
                                value={UploadMode.TUS}
                                description="Reliable chunked resumable upload"
                            >
                                TUS upload
                            </Radio>
                        </RadioGroup>
                    </FormControl>

                    <FormControl label="Video file">
                        <FileUpload
                            progressAmount={uploadVideoAction.percentage}
                            onFileSelected={file => {
                                setSelectedFile(file);
                                console.log("file")
                                console.log(file);
                            }}
                            disabled={formIsDisabled}
                            maxSize={100000000000}
                        />
                    </FormControl>

                    <FormControl
                        label="Video protection"
                        caption="Signed URLs allow for strict access control"
                        disabled={formIsDisabled}
                    >
                        <Checkbox
                            checked={signedUrlsRequired}
                            onChange={() => setSignedUrlsRequired(!signedUrlsRequired)}
                        >
                            Signed URLs
                        </Checkbox>
                    </FormControl>

                    <Button onClick={onSubmit} disabled={!selectedFile || formIsDisabled}>
                        Upload
                    </Button>

                </div>

            </div>

        </CenteredDiv>
    )
}