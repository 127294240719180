import {create} from "zustand";
import {persist} from "zustand/middleware";
import {User} from "./types";


interface Auth {
    user: User
    token: string
    platformId: number
}

interface AuthStoreState {
    auth: Auth | null
    lastPlatformId: number | null
}

const defaultAuthState: AuthStoreState = {
    auth: null,
    lastPlatformId: null,
}

export const useAuthStore = create<AuthStoreState>()(
    persist(
        (set, get) => defaultAuthState,
        {name: "state::auth"}
    )
)
